@font-face {
    font-family: "sukhumvitSetMedium";
    /*Can be any text*/
    src: local("sukhumvitSet-Medium"),
        url("../Fonts/sukhumvit/ttf/SukhumvitSet-Medium.ttf") format("truetype");
}

.font-bold {
    font-weight: 600;
}

.font-italic {
    font-style: italic;
}

body {
    margin: 0;
    position: relative;
    font-family: "sukhumvitSetMedium" !important;
    color: #fff !important;
}

p {
    margin: 0;
}

.wrapper {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-pc.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    /* height: 100lvh;
    width: 100lvw; */
    position: fixed;
    overflow-y: scroll;
}

/* new-ai */

.container-box {
    width: 1340px;
    margin: auto;
}

img {
    width: 100%;
}

.flex-section-start {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

a {
    text-decoration: none !important;
}

.group-container {
    padding-top: 10rem;
}

/* section-header */

.header-wrapper {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-head.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 130px;
    padding-top: 10px;
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.head-group-mb {
    display: none;
}


.head-group {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.head-logo {
    max-width: 250px;
    width: 100%;
}

.head-login,
.head-register {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 263px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}

.head-login {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-login.png);
}

.head-register {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-register.png);
    color: #FFF;
}

.head-login span {
    background: var(--radial-for-button, radial-gradient(76.32% 76.32% at 50% 0%, #FFF 0%, #E63E6D 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* section-start */

.start-text-mb {
    display: none;
}

.img-start-logo {
    max-width: 350px;
    width: 100%;
    margin: auto;
}

.section-start-banner {
    max-width: 752px;
    width: 100%;
    margin: auto;
}

.section-start-text h1 {
    color: #FFF;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-top: 2rem;
}

.section-start-text p {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-top: 1rem;
}

/* section-menu */
.section-menu-custom-slider {
    display: none;
}

.menu-flex img {
    width: 380px;
}

.menu-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.section-menu-custom-slider .splide__pagination {
    display: none;
}

/* section-title */
.box-bg-title {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/box-bg-title.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 44px;
}

.bg-title {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-title.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    max-Width: 462px;
    width: 100%;
    Height: 124px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 33.313px;
    font-weight: 700;
}

/* section-promotion */

.promotion-text span {
    color: var(--fbe-320, #FBE320);
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 28px;
    margin-top: 32px;
}

.promotion-banner,
.promotion-banner-mini {
    max-width: 638px;
    width: 100%;
}

.promotion-text {
    /* background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-text-promotion.png);
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    border-radius: 23.359px;
    border-top: 5.435px solid #f157a459;
    border-bottom: 5.435px solid #fb48a47d;
    background: linear-gradient(359deg, #e7417173 -114.07%, rgb(0 0 0 / 48%) 214.07%);
    box-shadow: 0px 1.998px 7.99px 0px rgba(0, 0, 0, 0.70);
    max-width: 492.15px;
    width: 100%;
    padding: 36px;
}

.promotion-text-box {
    height: 410px;
    overflow: auto;
    scrollbar-width: none;
}

.splide__pagination__page.is-active {
    background: #fff;
    transform: scale(1.4);
    z-index: 1;
}

.splide__pagination__page.is-active {
    background: #ffffff00 !important;
    transform: scale(1.4);
    z-index: 1;
}

.splide__pagination__page.is-active {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/splide-active.png) !important;
    background-size: 100% 100% !important;
    opacity: 1 !important;
    width: 72.063px !important;
    height: 13.512px !important;
    margin: 0rem 1rem !important;
}

.splide__pagination {
    bottom: -2em !important;
    gap: 0px !important;
}

.splide__pagination__page {
    background: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/splide.png) !important;
    background-size: 100% 100% !important;
    border-radius: 0px !important;
    width: 36.031px !important;
    height: 13.512px !important;
}

.promotion-banner-mini .splide__pagination {
    display: none;
}

.promotion-banner-mini {
    margin-top: 3.5rem;
}

/* section-games */

.category .category-slot,
.category .category-lotto,
.category .category-casino,
.category .category-sport {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    max-width: 66.5px;
    max-height: 65.909px;
    width: 100%;
    height: 100%;
}

.category.active .category-slot,
.category.active .category-lotto,
.category.active .category-casino,
.category.active .category-sport {
    width: 105.29px;
    height: 105.706px;
}

.category-slot {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/slot.png);

}

.category-lotto {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/lotto.png);
}

.category-casino {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/casino.png);
}

.category-sport {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/sport.png);
}

.icon-games {
    width: 224.266px;
}

.games-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 44px;
    padding: 0px 46px;
    margin-top: 30px;
}

.games-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.category {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/category-bg.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 114px;
    height: 112.986px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    font-size: 21.735px;
    opacity: 0.5;
}

.category.active {
    width: 156px;
    height: 156.617px;
    margin: auto;
    opacity: 1;
    font-size: 24.84px;
    color: #fb2091;
    font-weight: 700;
}

.games-category {
    background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-category.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 3rem;
}

/* section-question */
.section-question-banner {
    width: 660px;
}

.section-question-text {
    border-radius: 23.359px;
    border-top: 5.435px solid #f157a459;
    border-bottom: 5.435px solid #fb48a47d;
    background: linear-gradient(359deg, #e7417173 -114.07%, rgb(0 0 0 / 48%) 214.07%);
    box-shadow: 0px 1.998px 7.99px 0px rgba(0, 0, 0, 0.70);
    width: 492.15px;
    padding: 36px;
}

.section-question .flex-container {
    align-items: flex-end;
}

/* section-footer */
.section-footer-group {
    background: linear-gradient(180deg, rgb(231 65 113 / 18%) 0%, rgb(19 3 13 / 79%) 69.5%);
    border-radius: 53.457px 53.457px 0px 0px;
    max-width: 1340px;
    width: 100%;
    margin: 11rem auto 0rem auto;
    padding: 1.5rem 0rem;
}

.footerlogo {
    width: 690px;
    margin: auto;
}

.footer-text {
    width: 900px;
    margin: 70px auto;
    text-align: center;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 205.5%;
}

.icon-bank {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.icon-bank img {
    width: 74px;
    height: 74px;
}

@media only screen and (max-width: 1340px) {
    .container-box {
        width: 90%;
    }

    .promotion-text-box {
        height: 330px;
    }

    .icon-games {
        width: 144.266px;
    }

    .games-category {
        padding: 2rem;
    }

    .category.active {
        width: 126px;
        height: 126.617px;
    }

    .games-list {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }

    .menu-flex img {
        width: 200px;
    }

    .footer-text {
        width: 660px;
    }
}

@media only screen and (max-width: 690px) {

    .header-wrapper {
        background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-head-mb.png);
    }

    .head-login,
    .head-register,
    .start-text-pc {
        display: none;
    }

    .start-text-mb {
        display: block;
    }

    .group-container {
        padding-top: 8rem;
    }

    .head-group-mb {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .head-logo {
        max-width: 180px;
        width: 100%;
    }

    .head-login-mb,
    .head-register-mb {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 215px;
        height: 53px;
        font-size: 16px;
        font-weight: 700;
    }

    .head-login-mb span {
        background: var(--radial-for-button, radial-gradient(76.32% 76.32% at 50% 0%, #FFF 0%, #E63E6D 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .head-register-mb {
        color: #FFF;
    }

    .flex-section-start,
    .flex-container,
    .games-box {
        flex-direction: column;
    }

    .promotion-banner-mini {
        display: none;
    }

    .games-category {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 15px;
    }

    .container-box {
        max-width: 430px;
        width: 100%;
        margin: auto;
        padding: 0rem 1.5rem;
    }

    .img-start-logo {
        max-width: 150px;
        margin: 24px auto;
    }

    .section-start-text h1 {
        font-size: 22.261px;
    }

    .section-start-text p {
        font-size: 12px;
    }

    .section-start-banner {
        max-width: 382px;
    }

    .menu-flex {
        display: none;
    }

    .section-menu-custom-slider {
        display: block;
    }

    .bg-title {
        max-Width: 181px;
        Height: 67px;
        font-size: 18px;
    }

    .box-bg-title {
        background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/box-bg-title-mb.png);
        height: 73px;
    }

    .games-category {
        background-image: url(https://fs.cdnxn.com/landingAi-new/Images/new-ai/bg-category-mb.png);
    }

    .promotion-text {
        margin-top: 1rem;
        padding: 15px;
    }

    .section-question-banner {
        width: 100%;
    }

    .games-box {
        gap: 15px;
        padding: 0px;
    }

    .category .category-slot,
    .category .category-lotto,
    .category .category-casino,
    .category .category-sport {
        max-width: 38.743px;
        max-height: 38.743px;
    }

    .category.active .category-slot,
    .category.active .category-lotto,
    .category.active .category-casino,
    .category.active .category-sport {
        width: 61.239px;
        height: 61.239px;
    }

    .category {
        width: 66.417px;
        height: 66.417px;
        font-size: 10px;

    }

    .category.active {
        width: 90.732px;
        height: 90.732px;
        font-size: 13px;
    }

    .icon-games {
        max-width: 120px;
        width: 100%;
    }

    .section-question-text {
        width: 100%;
        padding: 20px;
    }

    .footerlogo {
        max-width: 300px;
        width: 100%;
    }

    .footer-text {
        max-width: 310px;
        width: 100%;
        margin: 20px auto;
        font-size: 12px;
    }

    .icon-bank img {
        width: 32.16px;
        height: 32.16px;
    }

    .promotion-text-box {
        font-size: 12px;
    }

    .promotion-text-box {
        height: 230px;
    }

    .section-question-text p {
        font-size: 14px;
    }

    .splide__pagination__page {
        width: 14.896px !important;
        height: 5.586px !important;
    }

    .splide__pagination__page.is-active {
        width: 29.791px !important;
        height: 5.586px !important;
    }
}